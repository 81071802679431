import React from "react";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Introduction
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            About
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Carl Sorenson,</span>
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              Director of Software Development
              for the EnCompass product at <a href="https://www.yesenergy.com/encompass-power-system-planning-software" target="_blank" rel="noreferrer">Yes Energy</a> (all
              views expressed here are my own).
              Left-leaning, church-going, science-loving, climate-watching,
              metric-promoting, serial-comma-using bidet evangelist.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              I live with my family in Charlotte, NC. I'm proud of my accomplished kids and wife.
              We like to go to the mountains a couple of times a year for hiking.
              We are active in the <a href="https://churchofjesuschrist.org" target="_blank" rel="noreferrer">Church of Jesus
                Christ of Latter-day Saints</a>. I recently published my first book, the young adult science fiction
              novel <a href="https://nissusnovel.com" target="_blank" rel="noreferrer">Nissus</a>.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              I am passionate
              about human rights, human development, civic involvement and
              democracy, and education, as well
              as topics around the climate crisis and climate/energy nexus.
              Politics and policy can be, and often is, life-and-death important.
            </p>
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*    I support the Democratic Party*/}
            {/*    (most of the time) as a vehicle for representing these values, but pragmatically.*/}
            {/*    Obviously, I do <em>not</em> believe people should never discuss politics -*/}
            {/*    political policies are life-or-death important.*/}
            {/*    A healthy democracy does require a healthy*/}
            {/*    and sane opposition party, and I hope that someday we have one.*/}
            {/*</p>*/}
            <p className={darkTheme ? "text-white-50" : ""}>
              Anyway, seriously, what kind of barbarian doesn't use a <a href="https://bidetking.com/" target="_blank" rel="noreferrer">bidet</a> yet?
            </p>

          </div>
          {/* About me content end */}
          {/* about me personal details start */}
          {/*<div className="col-lg-5 col-xl-4">*/}
          {/*  <div className="ps-lg-4">*/}
              {/*<ul*/}
              {/*  className={*/}
              {/*    "list-style-2 " +*/}
              {/*    (darkTheme ? "list-style-light text-light" : "")*/}
              {/*  }*/}
              {/*>*/}
              {/*  <li>*/}
              {/*    <span className="fw-600 me-2">Name:</span>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <span className="fw-600 me-2">Email:</span>*/}
              {/*    <a href="mailto:chat@simone.com"></a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
              {/*<a*/}
              {/*  href={resumeFile}*/}
              {/*  download*/}
              {/*  className="btn btn-primary rounded-pill"*/}
              {/*>*/}
              {/*  Download CV*/}
              {/*</a>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>3</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  kids
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>1</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  wife
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>2</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  EVs
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>About 5</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  extra kg
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
